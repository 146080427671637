// @import './timeline';
// @import "compass/css3";

* {
    // font-family: 'Cocogoose';
    font-family: 'Montserrat', sans-serif !important;
}

.expand-header{
    background: var(--ion-color-secondary);
    height: 212px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.inside-content{
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
    background: #fff;
    overflow: hidden;
    min-height: 65%;
    box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.2);
}

.list-offset{
    margin-top: -50px;
}

.valign-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

#wrap-empty{
    text-align: center;
    padding: 0 20px;
    width: 100%;
    opacity: .5;
}

ion-back-button {
    --color: #fff;
}